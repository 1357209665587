import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';
import Form from 'antd/lib/form';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import RequestSuccessStep from 'components/steps/RequestSuccessStep/RequestSuccessStep';
import PersonalDetailsStep from 'components/steps/PersonalDetailsStep/PersonalDetailsStep';
import AvailabilityStep from 'components/steps/AvailabilityStep/AvailabilityStep';
import UploadDocumentsStep from 'components/steps/UploadDocumentsStep/UploadDocumentsStep';
import ContactStep from 'components/steps/ContactStep/ContactStep';
import CostWelcomeStep from 'components/steps/CostWelcomeStep/CostWelcomeStep';
import { transformPersonalDetailsBeforeRequest } from 'framework/dataTransformations';
import { DEFAULT_SELECTED_WEEKDAYS } from 'framework/constants';
import { scrollToFormField } from 'framework/scrollerUtils';
import { setSubStepsHidden } from 'store/app/app.actions';
import {
  clientRequestStateSelector,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';
import {
  clientRequestUpdate,
  updateTransitionState,
} from 'store/client-request/client-request.actions';
import {
  ClientRequestFields,
  ClientRequestState,
  ClientRequestTransitionTypes,
  IClientRequest,
} from 'store/client-request/client-request.types';
import { questionnaireConfigForCustomerGet } from 'store/intermediate/intermediate.actions';

import { RequestState } from 'store/common.types';
import styles from './ClientRequestPage.module.sass';

const ClientRequestPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const requestState = useSelector(clientRequestStateSelector);

  const clientRequest = useSelector(currentClientRequestSelector);
  const stepConfig = useMemo(
    () => ({
      step: clientRequest?.step ?? 3,
      subStep: clientRequest?.subStep ?? 1,
    }),
    [clientRequest],
  );

  useEffect(() => {
    dispatch(setSubStepsHidden(false));
    dispatch(questionnaireConfigForCustomerGet());
  }, [dispatch]);

  const stopLoading = () => setLoading(false);

  const updateRequest = (fields: Partial<IClientRequest>) => {
    if (!clientRequest?.id) return;
    dispatch(clientRequestUpdate(clientRequest.id, fields, stopLoading, stopLoading));
  };

  useEffect(() => {
    if (clientRequest) {
      form.setFieldsValue({
        [ClientRequestFields.UserType]: _get(clientRequest, `${ClientRequestFields.UserType}`, ''),
        [ClientRequestFields.BusinessEmail]: _get(
          clientRequest,
          `${ClientRequestFields.BusinessEmail}`,
          '',
        ),
        [ClientRequestFields.Address]: {
          address2: _get(clientRequest, `${ClientRequestFields.Address}.address2`, ''),
          postalCode: _get(clientRequest, `${ClientRequestFields.Address}.postalCode`, ''),
          phone: _get(clientRequest, `${ClientRequestFields.Phone}`, ''),
          street: _get(clientRequest, `${ClientRequestFields.Address}.street`, ''),
          houseNumber: _get(clientRequest, `${ClientRequestFields.Address}.houseNumber`, ''),
          city: _get(clientRequest, `${ClientRequestFields.Address}.city`, ''),
          country: _get(clientRequest, `${ClientRequestFields.Address}.country.iso31661Alpha3`, ''),
        },
        [ClientRequestFields.BillingAddress]: {
          address2: _get(clientRequest, `${ClientRequestFields.BillingAddress}.address2`, ''),
          postalCode: _get(clientRequest, `${ClientRequestFields.BillingAddress}.postalCode`, ''),
          street: _get(clientRequest, `${ClientRequestFields.BillingAddress}.street`, ''),
          houseNumber: _get(clientRequest, `${ClientRequestFields.BillingAddress}.houseNumber`, ''),
          city: _get(clientRequest, `${ClientRequestFields.BillingAddress}.city`, ''),
          country: _get(
            clientRequest,
            `${ClientRequestFields.BillingAddress}.country.iso31661Alpha3`,
            '',
          ),
        },
        [ClientRequestFields.Phone]: _get(clientRequest, `${ClientRequestFields.Phone}`, ''),
        [ClientRequestFields.Availability]: {
          [ClientRequestFields.SoonestPossible]: _get(
            clientRequest,
            `${ClientRequestFields.SoonestPossible}`,
            '',
          ),
          [ClientRequestFields.StartDate]: _get(clientRequest, 'startDate', null),
        },
        [ClientRequestFields.InstallWeekdays]:
          _get(clientRequest, `${ClientRequestFields.InstallWeekdays}`, []) ||
          DEFAULT_SELECTED_WEEKDAYS,
        [ClientRequestFields.ContactType]: _get(
          clientRequest,
          `${ClientRequestFields.ContactType}`,
          '',
        ),
        [ClientRequestFields.Annotation]: _get(
          clientRequest,
          `${ClientRequestFields.Annotation}`,
          '',
        ),
      });
    }
  }, [form, clientRequest, dispatch]); // eslint-disable-line

  const onNextClick = async () => {
    if (!clientRequest?.id) return;
    let values;
    try {
      values = await form.validateFields();
    } catch (e) {
      if ((e as any).errorFields) {
        const errorField = (e as any).errorFields[0];
        if (errorField && errorField.name[0]) {
          scrollToFormField(errorField.name[0]);
          return;
        }
      }
      throw e;
    }
    const { id, state } = clientRequest;

    const isDocStepNext = stepConfig.subStep === 2;

    if (isDocStepNext && state === ClientRequestState.PendingDocument) {
      dispatch(updateTransitionState(id, ClientRequestTransitionTypes.documentsUploaded));
      return;
    }

    setLoading(true);

    const updateValues = transformPersonalDetailsBeforeRequest(values);

    const { step, subStep } = stepConfig;
    const isLast = subStep === 6;

    updateRequest({
      ...updateValues,
      step: isLast ? step + 1 : step,
      subStep: isLast ? 1 : subStep + 1,
    });
  };

  const onBackClick = () => updateRequest({ subStep: stepConfig.subStep - 1 });

  const renderStep = useCallback(() => {
    switch (stepConfig.subStep) {
      case 1:
        return (
          <CostWelcomeStep
            isLoading={loading}
            onNextClick={() => {
              setLoading(true);
              updateRequest({ subStep: stepConfig.subStep + 1 });
            }}
          />
        );
      case 2:
        return <PersonalDetailsStep form={form} pool={clientRequest?.pool} />;
      case 3:
        return <UploadDocumentsStep form={form} />;
      case 4:
        return <AvailabilityStep form={form} />;
      case 5:
        return <ContactStep clientRequest={clientRequest} form={form} />;
      case 6:
        return <RequestSuccessStep />;
    }
    return <div>{t('common:errors:somethingWrong')}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRequest]);

  const getButtonText = () => {
    const isServiceFeeEnabled = clientRequest?.pool?.isServiceFeeEnabled;
    const buttonText = t(
      `common:buttons:${isServiceFeeEnabled ? 'makeChargeableRequest' : 'sendRequest'}`,
    );
    return stepConfig.subStep === 5 ? buttonText : '';
  };

  const isFirstLastSteps = stepConfig.subStep === 1 || stepConfig.subStep === 6;

  const boxProps = {
    onNextClick,
    onBackClick,
    boxClassName: styles.requestContainer,
    nextButtonText: getButtonText(),
    nextButtonLoading: loading,
    showNextButton: !isFirstLastSteps,
    showBackButton: !isFirstLastSteps,
    nextOnEnter: false,
  };

  const titleText = `${t('stepper:customer:request:title')} - ${t(
    `stepper:customer:request:steps.${stepConfig.subStep}`,
  )} - Tendergy`;
  return (
    <>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <BoxContent
        {...boxProps}
        isContentLoading={
          requestState === RequestState.Saving ||
          requestState === RequestState.Loading ||
          clientRequest?.step !== 3
        }
      >
        {renderStep()}
      </BoxContent>
    </>
  );
};
export default ClientRequestPage;
